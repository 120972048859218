
  
  /* Body Styles */
  body {
    background-color: #5e6a75;
    font-family: Arial, sans-serif; /* Example font, replace with your preferred font */
    margin: 0;
    padding: 0;
    
  }
  
  /* Landing Page Styles */
  .landing-page {
    background-color: #5e6a75; /* Light gray background */
  
  }
  .bg-bor{
    border-bottom:black solid 5px;
  }
  
  /* Header Styles */
  .header {
    background-color:#D52814; /* Replace with your main background color */
    color: #fff; /* Replace with your main text color */
  }
  
  .header h1 {
    font-size: 1.8rem; /* Adjust font size as needed */
  }
  .left-aligned {
    text-align: left;
  }
  .number-style{
    text-decoration:underline;
    color:#D52814 ;
  }
  .circular-border{
    height:100%;
    width:100%;
    border-radius: 50%; /* Make the element a circle */
    border: 10px solid #212529; /* Define border thickness and color */
    padding:2px;
  
  }
  .service-butto{
    padding-bottom: 100px;
    
  }
  .color-btn{
    background-color:#d52814;
    border-color:#d52814 ;
  }
  .btn:hover{
    background-color:#88190c;
    border-color:#88190c ;
  }
  .border-black{
    border-color: 10px solid #212529;
  
  }
  #middle-section{
    background-color: #5e6a75; /* Light gray background */
  }

  .under-text{
   text-decoration: underline solid;
  }

  .align-left{
    text-align: left;
    margin-left:-15%;
  }
  .margin-up{
    margin-top: -2%;
    padding-bottom: -30%;
    
  }
  .p-contact{
    padding-top:10%;
  }
  .container2 {
    background-color: white;
    border-radius: 10px;
    margin-top:10%;
    margin-bottom: 10%;
    text-align: center;
  }
  .container{
    align-items: center;
    margin-right:auto;
    margin-left:auto;
    border:#212529 5px solid;
    border-radius: 15px;
    width: 90%;
  }
  
  .form-title {
    font-size: 1.5rem;
    margin-bottom: 20%;
  }
  .p-left{
    padding-left: 10%;
    padding-right: 10%;
  }
  .drop-b{
    padding-bottom: 63%;
  }
  .title-f{
    font-size: 4rem;
    margin-bottom: 5%;
    text-decoration: underline solid;
    text-align: center;
    color:black;
  }
.wid-cen{
  width:30%;
 content: center;
}
.marg-sub{
  margin-left:39%;
}
.text-c{
  text-align:center;
}
.text-d{
 
  text-decoration: underline solid;
}
.submit-mar{
 margin-left: 16.8%;
  margin-right:30%;
  margin-top:.5%;
  width: 66.5%;
}
.left-pad{
  padding-left:15%;
 
}
.contact-options {
  display: flex; /* Make the container horizontal */
  justify-content: space-between; /* Distribute checkboxes evenly */
  margin-bottom: 1rem; /* Add some margin for spacing */
}

.checkbox-container {
  display: flex;
  align-items: center; /* Align labels and checkboxes vertically */
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px; /* Add spacing between checkbox and label */
}
.cb-text{
  padding-left:40%
}