@media (min-width: 575px) {
  .hide-large {
    display: none; /* Redundant for clarity */
  };

  

}
@media (max-width: 575px) {
  .hide-small{
    display: none; /* Redundant for clarity */
  };
  .info-phone{
    margin-right:30%;
  };
 
}

  
  /* Body Styles */
  body {
    font-family: Arial, sans-serif; /* Example font, replace with your preferred font */
    margin: 0;
    padding: 0;
    
  }
  
  /* Landing Page Styles */
  .landing-page {
    background-color: #5e6a75; /* Light gray background */
    border-bottom:black solid 5px;
    margin-right:-10px;
    margin-left:10px;
    margin-top:-10px;
    padding-right: 5%;
  
  }
  .bg-bor{
    border-bottom:black solid 5px;
  }
  .bg-top{
    border-top:black solid 5px;
  }
  
  /* Header Styles */
  .header {
    background-color:#D52814; /* Replace with your main background color */
   color:#212529;
    border-bottom:black solid 5px;
  }
  
  .header h1 {
    font-size: 1.8rem; /* Adjust font size as needed */
  }
  .left-aligned {
    text-align: left;
  }
  .number-style{
    text-decoration:underline;
    color:#D52814 ;
  }
  .circular-border{
    height:100%;
    width:100%;
    border-radius: 50%; /* Make the element a circle */
    border: 10px solid #212529; /* Define border thickness and color */
    padding:2px;
  
  }
  .service-butto{
    padding-bottom: 100px;
    
  }
  .color-btn{
    background-color:#d52814;
    border-color:#d52814 ;
  }
  .btn:hover{
    background-color:#88190c;
    border-color:#88190c ;
  }
  #middle-section{
    background-color: black; /* Light gray background */
  }
  .location{
    padding-top: -50px;
  }
  .mb-m{
    margin-bottom: 40px;
  }
  .loc-text{
    text-decoration: underline;
    background-color:#D52814;
    border: #212529 solid 5px;
    border-radius: 10px;;
    color:#212529;
  }
  
    




  .mb-m{
    margin-bottom: 40px;
  }
  .info-box {
    border:#212529 solid 5px;
    background: linear-gradient(to right, #212529 -20%, #D52814 100%);  /* Define the gradient */ /* White background */
    margin-left: 0;
    padding: 15px 20px; /* Adjust padding as needed */
    display: inline-block; /* Allow text to wrap next to it */
    border-radius: 0 50px 50px 0; 
  }
 
 
  .first-h{
    margin-left: -17%;
    text-decoration: underline solid;
  } 

  
  .second-h{
    margin-left:-40px;
    text-decoration: underline solid ;
   
  }
  .first-a{
    margin-right: -17%;
  }
  .second-a{
    margin-right: -6%;
  }
  .third-a{
    margin-right: 0px;
  }
  .down{
  margin-top: 40px;
  }
  .under-text{
   text-decoration: underline solid;
   text-decoration-color: #fff;
  }
  .row-padding{
  margin-top: -40px;
  margin-bottom: -40px;
  }








  .align-left{
    text-align: left;
    margin-left:-100px;
  }
  .margin-up{
  padding-top: 2%;
  padding-bottom: 2%;
  margin-right:-6%;

  }


.text-grey{
  color: white;
    
}
.text-grey:hover{
  color:black;
}
.home{
  margin-left: 10%;
}






/*image container*/


.img-bg{

 margin-top: -8.45%;
 border-bottom: #212529 solid 5px;
 
}
.left-img{
margin-left:-12%;
margin-top:-5;
}

.img-front-format{
  padding:12%;
  height: 80vh;
  width: 80vh;
  margin-bottom:10%;
}

.img-front-format:hover{
  opacity: 50%;

}



/*modal --------------------------------------------------------*/

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
} 
.modal-footer {
  position: sticky;
  top:0;
  bottom: 0;
  right: 0;
}
.modal-content:nth-child(div) {
text-align: left;
}
.modal-content {
  border:#212529 solid 5px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 50%;
  height:50%;
  overflow: hidden;
  overflow-y:scroll;
}
.u-title{
  text-decoration: underline solid ;
}



/* ----------------------------------Picture section --------------------------------------------------------*/
.titlecontainer{
  background-color: #5e6a75; /* Light gray background */
  margin-top: 5%;
  margin-bottom:5%;
}
.left-title{
  color:#212529;
  text-align: left;
  font-family:serif;
  margin-left:5%;
  text-decoration: underline solid;
  text-decoration-color:#212529;
  background-color: #D52814;
  border:black solid 5px;
  border-radius: 40px;
  padding-right:2%;
  padding-left:2%;
  
}
.left-title2{
  color:#212529;
  text-align: center;
  font-family:serif;
  margin-left:5%;
  text-decoration: underline solid;
  text-decoration-color:#212529;
  background-color: #D52814;
  border:black solid 5px;
  border-radius: 40px;
  padding-right:2%;
  padding-left:2%;
 
}
.left-title3{
  color:#212529;
  text-align: center;
  font-family:'Times New Roman', serif;
  font-weight: 700;
  text-decoration: underline solid;
  text-decoration-color:#212529;
  padding-right:2%;
  padding-left:2%;
  font-size: 50pt;
 
}

.image-section {
  background-color: #212529;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.image-container {
  flex: 1;
  text-align: center;
  padding: 20px; /* Adjust padding as needed */
  
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius:10px;
}


/*--------------------------------------------banner ------------------ */

.banner-container {

}
.move-right{
  margin-left:90%;
  background-color: #d52814;
  margin-right:-50%;

}
.custom-rounded-squarebanner{

  border-radius:15%;
  height: 5vh;
  width: 5vh;
  margin-bottom:10%;
}

.banner {
  position: absolute;
  white-space: nowrap;
  animation: banner-scroll 50s linear infinite; /* Adjust animation duration as needed */
  width:100%;
  padding-bottom: 5%;
  
}
.banner-text{
  color:#212529;
 margin-right:20%;
}

@keyframes banner-scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
