 
  /* Media query targeting screens less than or equal to 575px */
@media (min-width: 575px) {
  .hide-on-large-screens {
    display: none; /* Redundant for clarity */
  }
 
}

@media (max-width: 575px) {
  .hide-on-small-screens {
    display: none; /* Redundant for clarity */
  }
  .cent-cvh{
    text-align:center;
    margin-bottom:5%; 
    align-content: center;
   }
   .bghoul{
    align-content: center;
    text-align:center;
   }

}
  /* Body Styles */
  body {
    background-color: #5e6a75;
    font-family: Arial, sans-serif; /* Example font, replace with your preferred font */
    margin: 0;
    padding: 0;
  }
  
  /* Landing Page Styles */
  .landing-page {
    background-color: #5e6a75; /* Light gray background */
  }
  .bg-bor{
    border-bottom:black solid 5px;
  }
  .bg-logo{
    border:black solid 5px;
    background-color: #5e6a75;
    border-radius: 40px;
    padding-right:2%;
    padding-left:2%;
  }
  
  /* Header Styles */
  .header {
    background-color:#D52814; /* Replace with your main background color */
    color: #5e6a75; /* Replace with your main text color */
  }
  
  
  .header h1 {
    font-size: 1.8rem; /* Adjust font size as needed */
  }
  .left-aligned {
    text-align: left;
  }
  .number-style{
    text-decoration:underline;
    color:#D52814 ;
  }
  .circular-border{
    height:100%;
    width:100%;
    border-radius: 50%; /* Make the element a circle */
    border: 10px solid #212529; /* Define border thickness and color */
    
  
  }
  .service-butto{
    padding-bottom: 100px;
  }
  .color-btn{
    background-color:#d52814;
    border-color:#d52814 ;
    color:white;
   
  }

  .btn:hover{
    background-color:#88190c;
    border-color:black;
  }

  .border-black{
    border-color: 10px solid #212529;
  }

  #middle-section{
    background-color: #5e6a75; /* Light gray background */
  }

  .location{
    padding-top: -50px;
  }
 
  .under-text{
   text-decoration: underline solid;
  }
  .align-left{
    text-align: left;
    margin-left:-100px;
  }

  .margin-up{
    margin-top: -2%;
    padding-bottom: -30%;
    
  }
 
  .nav-bar{
  margin-bottom:1.5%;
  border-bottom: #212529 5px solid;
  }
 
  .b-bottom{
    border-bottom:black solid 5px;
    }

.text-quote{
  text-size-adjust: 20pt;
  margin-right:10%;
  color:white;
}

.btn ::before.text-quote{
height:max-content;
}

.title-g{
  color:white;
  font-size: x-large;
  margin-top:5%;
}

.title-f{
  color:black
}

