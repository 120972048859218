
  
  /* Body Styles */
  body {
    background-color: #5e6a75;
    font-family: Arial, sans-serif; /* Example font, replace with your preferred font */
    margin: 0;
    padding: 0;
    
  }
  
  /* Landing Page Styles */
  .landing-page {
    background-color: #5e6a75; /* Light gray background */
  
  }
  .bg-bor{
    border-bottom:black solid 5px;
    
  }
  
  /* Header Styles */
  .header {
    background-color:#D52814; /* Replace with your main background color */
    color: #fff; /* Replace with your main text color */
  }
  
  .header h1 {
    font-size: 1.8rem; /* Adjust font size as needed */
  }
  .left-aligned {
    text-align: left;
  }
  .number-style{
    text-decoration:underline;
    color:#D52814 ;
  }
  .circular-border{
    height:100%;
    width:100%;
    border-radius: 50%; /* Make the element a circle */
    border: 10px solid #212529; /* Define border thickness and color */
    padding:2px;
  
  }
  .service-butto{
    padding-bottom: 100px;
    
  }
  .color-btn{
    background-color:#d52814;
    border-color:#d52814 ;
  }
  .btn:hover{
    background-color:#88190c;
    border-color:#88190c ;
  }
  .border-black{
    border-color: 10px solid #212529;
  
  }
  #middle-section{
    background-color: #5e6a75; /* Light gray background */
  }
  .location{
    padding-top: -50px;
  }
  .loc-text{
    text-decoration: underline;
    background-color:#D52814;
    border: #212529 solid 5px;
    border-radius: 10px;;
   
    padding-right: 20px;
  }

  .mb-m{
    margin-bottom: 40px;
  }
  
 
  .first-a{
    margin-right: -17%;
    
  }
  .first-h{
    margin-left: -17%;
    text-decoration: underline solid;
  }
  .second-a{
    margin-right: -6%;
  
  }
  .second-h{
    margin-left:-40px;
    text-decoration: underline solid white;
  }
  .third-a{
    margin-right: 0px;
  }
  .fourth-a{
    margin-right: 10px;
  }
  .down{
  margin-top: 40px;
  }
  .under-text{
   text-decoration: underline solid;
  }
  .row-padding{
  margin-top: -40px;
  margin-bottom: -40px;
  }
  .align-left{
    text-align: left;
    margin-left:-100px;
  }
  .margin-up{
    margin-top: -2%;
    padding-bottom: -30%;
    
  }
 .custom-rounded-square2{
  object-Fit: 'cover';
  
  
 }
 .container{
  background-color: azure;
 }

  .custom-rounded-square{

    border-radius:15%;
    height: 50vh;
    width: 50vh;
    padding-right:-50%;
    margin-bottom:10%;
  }
  .bd-black:hover{
    opacity: 50%;

  }
  .title-f{
    color:black;
  }
  

  .left-title2{
    color:#212529;
    text-align: center;
    font-family:serif;
    margin-left:5%;
    text-decoration: underline solid;
    text-decoration-color:#212529;
    background-color: #D52814;
    border:black solid 5px;
    border-radius: 40px;
    padding-right:2%;
    padding-left:2%;
   
  }
 